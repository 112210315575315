export const menuItems = [
    {
        label: "menuitems.home.text",
        icon: "ri-file-user-line",
        isMenuCollapsed: false,
        link: '/',
    },

    {
        label: "menuitems.transaction.text",
        icon: "ri-file-user-line",
        isMenuCollapsed: false,
        link: '/transaction',
    },

    {
        label: "menuitems.earn-interest.text",
        icon: "ri-file-user-line",
        isMenuCollapsed: false,
        link: '/earn-interest',
    },

    {
        label: "menuitems.admin.text",
        icon: "ri-file-user-line",
        permission: "manage admins",
        isMenuCollapsed: false,
        link: '/profile',
    },

];

